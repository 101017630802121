import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Customerinfo, followType } from '@/api/ceshi_xiugai2/deliverycustomerinfo';
import { getEntrance as _getEntrance, getCustomerLevel as _getCustomerLevel, getLabelpeer as _getLabelpeer } from '@/api/ceshi_xiugai/whole';
import { checkCustomerByMobile } from '@/api/updateUserInfo/users';
export default {
  data: function data() {
    return {
      calls: '',
      MobileAddress: '',
      dialogShow: false,
      name: '',
      tel: '',
      cphoneShow: false,
      cphone1Show: false,
      cphone2Show: false,
      agentnameShow: false,
      select: true,
      customer_data_id: this.$route.query.id,
      activeNames: ['0'],
      //默认收齐状态
      reverse: true,
      detailForm: {},
      entrance: 0,
      //入学时间
      customer: [],
      //客户标签
      StudentLevel: [],
      Student: '',
      //学员级别
      Students: '',
      khbq: [] //客户标签
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var that = this;
    window.addEventListener('storage', function (item) {
      var names = localStorage.getItem('Incoming');
      if (names == 'ringingIb') {
        var call = localStorage.getItem('call');
        checkCustomerByMobile({
          mobile: call
        }).then(function (item) {
          var data = item.data;
          if (data.flag == 1) {
            that.calls = data.cname;
            that.MobileAddress = data.mobile_address;
          } else {
            that.calls = call;
            that.MobileAddress = data.mobile_address;
          }
          that.dialogShow = true;
        });
      }
      if (item.newValue != 'RINGING') {
        that.dialogShow = false;
        localStorage.setItem('Incoming', '');
      }
    });
    this.GetDetails(this.customer_data_id);
  },
  methods: {
    DialogClick: function DialogClick() {
      this.dialogShow = false;
    },
    //入学时间
    getEntrance: function getEntrance() {
      var _this = this;
      _getEntrance().then(function (res) {
        res.data.map(function (item) {
          if (item.id == _this.entrance) {
            _this.entrance = item.value;
          }
        });
      });
    },
    //学员级别
    getCustomerLevel: function getCustomerLevel() {
      var _this2 = this;
      _getCustomerLevel().then(function (res) {
        var _loop = function _loop(item) {
          if (res.data[item].id == _this2.Student) {
            _this2.Students = res.data[item].name;
          }
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              if (dats.id == _this2.Student) {
                _this2.StudentLevel.push(res.data[item].name);
                _this2.StudentLevel.push(dats.name);
                _this2.Students = _this2.StudentLevel.join('/');
              }
            });
          }
        };
        for (var item in res.data) {
          _loop(item);
        }
      });
    },
    //客户标签
    getLabelpeer: function getLabelpeer() {
      var _this3 = this;
      _getLabelpeer().then(function (res) {
        res.data.map(function (item) {
          _this3.khbq.map(function (i) {
            if (item.id == i) {
              _this3.customer.push(item);
            }
          });
        });
      });
    },
    GetDetails: function GetDetails(customer_data_id) {
      var _this4 = this;
      //编辑获取详情
      Customerinfo({
        customer_data_id: customer_data_id
      }).then(function (res) {
        _this4.detailForm = res.data;
        var data = res.data[0].info;
        _this4.name = data.cname;
        _this4.tel = data.mobile;
        _this4.entrance = data.entrance == 0 ? '' : data.entrance;
        _this4.Student = data.customer_level;
        _this4.khbq = data.labelpeer.split(',');
        if (_this4.khbq[0] == '') {
          _this4.khbq.shift();
        }
        //入学时间
        _this4.getEntrance();
        //学员级别
        _this4.getCustomerLevel();
        //客户标签
        _this4.getLabelpeer();
      });
    },
    changeShowValue: function changeShowValue(val, type) {
      if (val == 'cphone') {
        this.cphoneShow = type;
      } else if (val == 'cphone1') {
        this.cphone1Show = type;
      } else if (val == 'cphone2') {
        this.cphone2Show = type;
      } else if (val == 'agent_name') {
        this.agentnameShow = type;
      }
    },
    //tab页切换
    tabClick: function tabClick(targetName) {
      this.StudentLevel = [];
      this.customer = [];
      var data = this.detailForm[targetName.index].info;
      this.name = data.cname;
      this.tel = data.mobile;
      this.entrance = data.entrance == 0 ? '' : data.entrance;
      this.Student = data.customer_level;
      this.khbq = data.labelpeer.split(',');
      if (this.khbq[0] == '') {
        this.khbq.shift();
      }
      //入学时间
      this.getEntrance();
      //学员级别
      this.getCustomerLevel();
      //客户标签
      this.getLabelpeer();
    }
  }
};
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inFormation df" }, [
    _c("div", { staticClass: "main-left" }, [
      _c("div", { staticClass: "photo-box" }, [
        _c("img", {
          attrs: {
            src: "https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png",
            alt: "",
          },
        }),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "Pname",
            staticStyle: { color: "#000", "margin-bottom": "0" },
          },
          [_vm._v(_vm._s(_vm.name))]
        ),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "Pname",
            staticStyle: { color: "#888", "margin-top": "10px" },
          },
          [_vm._v(_vm._s(_vm.tel))]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main-right", staticStyle: { position: "relative" } },
      [
        _c(
          "el-tabs",
          { attrs: { type: "border-card" }, on: { "tab-click": _vm.tabClick } },
          _vm._l(_vm.detailForm, function (detail, index) {
            return _c("el-tab-pane", { key: index }, [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(detail.title)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fl tabLeft" }, [
                _c(
                  "div",
                  { staticClass: "RequiredInfosrmation" },
                  [
                    _c("h3", [_vm._v("基本信息")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                          [
                            _c("ul", [
                              _c(
                                "li",
                                {
                                  staticStyle: { "border-right-width": "0px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "textAlign",
                                      staticStyle: { width: "22%" },
                                    },
                                    [_vm._v("客户名称：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "76.6666%" } },
                                    [_vm._v(_vm._s(detail.info.cname))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.changeShowValue("cphone", true)
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.changeShowValue(
                                        "cphone",
                                        false
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "textAlign",
                                      staticStyle: { width: "22%" },
                                    },
                                    [_vm._v("电话：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.cphoneShow,
                                          expression: "!cphoneShow",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "76.6666%",
                                        position: "relative",
                                      },
                                    },
                                    [_vm._v(_vm._s(detail.info.mobile))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.cphoneShow,
                                          expression: "cphoneShow",
                                        },
                                      ],
                                      staticClass: "input-show",
                                      staticStyle: { width: "77.6666%" },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "58%",
                                          border: "none",
                                          "padding-left": "0",
                                          "background-color": "#fff",
                                        },
                                        attrs: { disabled: true },
                                        model: {
                                          value: detail.info.mobile,
                                          callback: function ($$v) {
                                            _vm.$set(detail.info, "mobile", $$v)
                                          },
                                          expression: "detail.info.mobile",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "40%",
                                          "padding-left": "20px",
                                          border: "none",
                                        },
                                        attrs: { disabled: true },
                                        model: {
                                          value: detail.info.mobile_address,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              detail.info,
                                              "mobile_address",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detail.info.mobile_address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              detail.info.mobile1
                                ? _c(
                                    "li",
                                    {
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.changeShowValue(
                                            "cphone1",
                                            true
                                          )
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.changeShowValue(
                                            "cphone1",
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "textAlign",
                                          staticStyle: { width: "22%" },
                                        },
                                        [_vm._v("联系电话2：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.cphone1Show,
                                              expression: "!cphone1Show",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "76.6666%",
                                            position: "relative",
                                          },
                                        },
                                        [_vm._v(_vm._s(detail.info.mobile1))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.cphone1Show,
                                              expression: "cphone1Show",
                                            },
                                          ],
                                          staticClass: "input-show",
                                          staticStyle: { width: "77.6666%" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "58%",
                                              border: "none",
                                              "padding-left": "0",
                                              "background-color": "#fff",
                                            },
                                            attrs: { disabled: true },
                                            model: {
                                              value: detail.info.mobile1,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  detail.info,
                                                  "mobile1",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.info.mobile1",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "40%",
                                              "padding-left": "20px",
                                              border: "none",
                                            },
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                detail.info.mobile1_address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  detail.info,
                                                  "mobile1_address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.info.mobile1_address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              detail.info.mobile2
                                ? _c(
                                    "li",
                                    {
                                      on: {
                                        mouseenter: function ($event) {
                                          return _vm.changeShowValue(
                                            "cphone2",
                                            true
                                          )
                                        },
                                        mouseleave: function ($event) {
                                          return _vm.changeShowValue(
                                            "cphone2",
                                            false
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "textAlign",
                                          staticStyle: { width: "22%" },
                                        },
                                        [_vm._v("联系电话3：")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.cphone2Show,
                                              expression: "!cphone2Show",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "76.6666%",
                                            position: "relative",
                                          },
                                        },
                                        [_vm._v(_vm._s(detail.info.mobile2))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.cphone2Show,
                                              expression: "cphone2Show",
                                            },
                                          ],
                                          staticClass: "input-show",
                                          staticStyle: { width: "77.6666%" },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "58%",
                                              border: "none",
                                              "padding-left": "0",
                                              "background-color": "#fff",
                                            },
                                            attrs: { disabled: true },
                                            model: {
                                              value: detail.info.mobile2,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  detail.info,
                                                  "mobile2",
                                                  $$v
                                                )
                                              },
                                              expression: "detail.info.mobile2",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticStyle: {
                                              width: "40%",
                                              "padding-left": "20px",
                                              border: "none",
                                            },
                                            attrs: { disabled: true },
                                            model: {
                                              value:
                                                detail.info.mobile2_address,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  detail.info,
                                                  "mobile2_address",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detail.info.mobile2_address",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "textAlign",
                                    staticStyle: { width: "22%" },
                                  },
                                  [_vm._v("邮箱：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { width: "76.6666%" } },
                                  [_vm._v(_vm._s(detail.info.cemail))]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                          [
                            _c("ul", [
                              _c(
                                "li",
                                {
                                  on: {
                                    mouseenter: function ($event) {
                                      return _vm.changeShowValue(
                                        "agent_name",
                                        true
                                      )
                                    },
                                    mouseleave: function ($event) {
                                      return _vm.changeShowValue(
                                        "agent_name",
                                        false
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticStyle: { width: "22%" } }, [
                                    _vm._v("学员来源(花名)："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.agentnameShow,
                                          expression: "!agentnameShow",
                                        },
                                      ],
                                      staticStyle: { width: "76.6666%" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          detail.info.source_client_info
                                            .flower_name
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.agentnameShow,
                                          expression: "agentnameShow",
                                        },
                                      ],
                                      staticClass: "input-show",
                                      staticStyle: { width: "77.6666%" },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "58%",
                                          border: "none",
                                          "padding-left": "0",
                                          "background-color": "#fff",
                                        },
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            detail.info.source_client_info
                                              .flower_name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              detail.info.source_client_info,
                                              "flower_name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detail.info.source_client_info.flower_name",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "40%",
                                          "padding-left": "20px",
                                          border: "none",
                                        },
                                        attrs: { disabled: true },
                                        model: {
                                          value:
                                            detail.info.source_client_info
                                              .leibie,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              detail.info.source_client_info,
                                              "leibie",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detail.info.source_client_info.leibie",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticStyle: { "border-right-width": "0px" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "textAlign",
                                      staticStyle: { width: "22%" },
                                    },
                                    [_vm._v("微信：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "76.6666%" } },
                                    [_vm._v(_vm._s(detail.info.wxnum))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "textAlign",
                                    staticStyle: { width: "22%" },
                                  },
                                  [_vm._v("QQ：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { width: "76.6666%" } },
                                  [_vm._v(_vm._s(detail.info.qq))]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 } },
                          [
                            _c("ul", [
                              _c(
                                "li",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "border-bottom-width": "1px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "textAlign",
                                      staticStyle: {
                                        width: "11%",
                                        height: "40px",
                                      },
                                    },
                                    [_vm._v("创建附注：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "88%",
                                        height: "40px",
                                        "line-height": "40px",
                                      },
                                    },
                                    [_vm._v(_vm._s(detail.info.create_notes))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "border-bottom-width": "1px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "textAlign",
                                      staticStyle: {
                                        width: "11%",
                                        height: "40px",
                                      },
                                    },
                                    [_vm._v("备注：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "88%",
                                        height: "40px",
                                        "line-height": "40px",
                                      },
                                    },
                                    [_vm._v(_vm._s(detail.info.notes))]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "SelectiveInformation" },
                  [
                    _c("h3", [_vm._v("背景意向")]),
                    _vm._v(" "),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                          [
                            _c("ul", [
                              _c("li", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "textAlign",
                                    staticStyle: { width: "22%" },
                                  },
                                  [_vm._v("当前学历：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { width: "76.6666%" } },
                                  [_vm._v(_vm._s(detail.info.graduation_xueli))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "textAlign",
                                    staticStyle: { width: "22%" },
                                  },
                                  [_vm._v("毕业院校：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { width: "76.6666%" } },
                                  [_vm._v(_vm._s(detail.info.graduate_school))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "textAlign",
                                    staticStyle: { width: "22%" },
                                  },
                                  [_vm._v("意向地区：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { width: "76.6666%" } },
                                  [_vm._v(_vm._s(detail.info.ittngj))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticStyle: { "border-bottom-width": "1px" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "textAlign",
                                      staticStyle: { width: "22%" },
                                    },
                                    [_vm._v("报考项目：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { width: "76.6666%" } },
                                    [_vm._v(_vm._s(detail.info.ittnxl))]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "textAlign",
                                    staticStyle: { width: "22%" },
                                  },
                                  [_vm._v("意向院校：")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      width: "76.6666%",
                                      height: "auto",
                                      "padding-left": "0",
                                    },
                                  },
                                  [
                                    _c("el-select", {
                                      staticClass: "ittnyx",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "",
                                        disabled: true,
                                        multiple: "",
                                        filterable: "",
                                        remote: "",
                                      },
                                      model: {
                                        value: detail.info.ittnyx,
                                        callback: function ($$v) {
                                          _vm.$set(detail.info, "ittnyx", $$v)
                                        },
                                        expression: "detail.info.ittnyx",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 } },
                          [
                            _c("ul", [
                              _c("li", [
                                _c("span", { staticClass: "textAlign" }, [
                                  _vm._v("毕业专业："),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(detail.info.graduation_major)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("span", { staticClass: "textAlign" }, [
                                  _vm._v("意向专业："),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      height: "auto",
                                      "padding-left": "0",
                                    },
                                  },
                                  [
                                    _c("el-select", {
                                      staticClass: "ittnyx",
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "",
                                        disabled: true,
                                        multiple: "",
                                        filterable: "",
                                        remote: "",
                                      },
                                      model: {
                                        value: detail.info.ittnzy,
                                        callback: function ($$v) {
                                          _vm.$set(detail.info, "ittnzy", $$v)
                                        },
                                        expression: "detail.info.ittnzy",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticStyle: { "border-bottom-width": "1px" },
                                },
                                [
                                  _c("span", { staticClass: "textAlign" }, [
                                    _vm._v("意向简章："),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        height: "auto",
                                        "padding-left": "0",
                                      },
                                    },
                                    [
                                      _c("el-select", {
                                        staticClass: "ittnyx",
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          placeholder: "",
                                          disabled: true,
                                          multiple: "",
                                          filterable: "",
                                          remote: "",
                                        },
                                        model: {
                                          value: detail.info.ittnjz,
                                          callback: function ($$v) {
                                            _vm.$set(detail.info, "ittnjz", $$v)
                                          },
                                          expression: "detail.info.ittnjz",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticStyle: { "border-bottom-width": "1px" },
                                },
                                [
                                  _c("span", { staticClass: "textAlign" }, [
                                    _vm._v("预算："),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(detail.info.budget)),
                                  ]),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-collapse",
                      {
                        model: {
                          value: _vm.activeNames,
                          callback: function ($$v) {
                            _vm.activeNames = $$v
                          },
                          expression: "activeNames",
                        },
                      },
                      [
                        _c(
                          "el-collapse-item",
                          { attrs: { name: "1" } },
                          [
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "title" }, [
                                _vm._v("其他信息"),
                              ]),
                              _vm._v(" "),
                              _c("h5", [_vm._v("   (点击展开/收缩)")]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c("ul", [
                                      _c("li", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("性别：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "input-show",
                                            staticStyle: { width: "76.66666%" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                                    " +
                                                _vm._s(
                                                  detail.info.sex == 0
                                                    ? "男"
                                                    : "女"
                                                ) +
                                                "\n                                                "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("预计入学：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { width: "76.6666%" },
                                          },
                                          [_vm._v(_vm._s(_vm.entrance))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("岗位：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { width: "76.6666%" },
                                          },
                                          [_vm._v(_vm._s(detail.info.post))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("学术成绩：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { width: "76.6666%" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                detail.info
                                                  .academic_achievements
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c("ul", [
                                      _c("li", [
                                        _c(
                                          "span",
                                          { staticClass: "textAlign" },
                                          [_vm._v("年龄：")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              detail.info.age == "0"
                                                ? ""
                                                : detail.info.age
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "span",
                                          { staticClass: "textAlign" },
                                          [_vm._v("座机：")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(_vm._s(detail.info.telephone)),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "span",
                                          { staticClass: "textAlign" },
                                          [_vm._v("职业：")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(detail.info.occupation)
                                          ),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("li", [
                                        _c(
                                          "span",
                                          { staticClass: "textAlign" },
                                          [_vm._v("语言成绩：")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              detail.info.language_achievement
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c("ul", [
                                      _c("li", [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("行业：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: { width: "76.6666%" },
                                          },
                                          [_vm._v(_vm._s(detail.info.industry))]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            "border-bottom-width": "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "textAlign",
                                              staticStyle: { width: "22%" },
                                            },
                                            [_vm._v("护照号码：")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                width: "76.6666%",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  detail.info.passport_number
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 12,
                                      xl: 12,
                                    },
                                  },
                                  [
                                    _c("ul", [
                                      _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            "border-bottom-width": "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "textAlign" },
                                            [_vm._v("发起沟通网址：")]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                detail.info
                                                  .communication_website
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            "border-bottom-width": "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "textAlign" },
                                            [_vm._v("性格：")]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(detail.info.character)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 24,
                                      xl: 24,
                                    },
                                  },
                                  [
                                    _c("ul", [
                                      _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            "border-bottom-width": "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "textAlign",
                                              staticStyle: { width: "11%" },
                                            },
                                            [_vm._v("详细住址：")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticStyle: { width: "88%" } },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  detail.info.detailed_address
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _c(
                                  "el-col",
                                  {
                                    attrs: {
                                      xs: 24,
                                      sm: 24,
                                      md: 24,
                                      lg: 24,
                                      xl: 24,
                                    },
                                  },
                                  [
                                    _c("ul", [
                                      _c(
                                        "li",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "border-bottom-width": "1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "textAlign",
                                              staticStyle: { width: "11%" },
                                            },
                                            [_vm._v("客户标签：")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "label-peer",
                                              staticStyle: {
                                                width: "88%",
                                                height: "40px",
                                              },
                                            },
                                            _vm._l(
                                              _vm.customer,
                                              function (item) {
                                                return _c(
                                                  "el-checkbox",
                                                  {
                                                    key: item.id,
                                                    attrs: {
                                                      label: item.id,
                                                      disabled: "",
                                                    },
                                                    model: {
                                                      value: _vm.select,
                                                      callback: function ($$v) {
                                                        _vm.select = $$v
                                                      },
                                                      expression: "select",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(item.value)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "fl tabRight" }, [
                _c("div", { staticStyle: { "padding-top": "15px" } }, [
                  _c(
                    "div",
                    { staticStyle: { "padding-top": "15px" } },
                    [
                      _c(
                        "el-form",
                        {
                          attrs: {
                            "label-position": "labelPosition",
                            "label-width": "30%",
                          },
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { label: "学员级别:" },
                            },
                            [
                              _c("el-input", {
                                attrs: { value: _vm.Students, disabled: "" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "placemen" },
                  [
                    detail.follwup.length != 0
                      ? _c(
                          "el-timeline",
                          { attrs: { reverse: _vm.reverse } },
                          _vm._l(detail.follwup, function (activity, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  placement: "top",
                                  timestamp: activity.create_time,
                                },
                              },
                              [
                                _c("span", { staticClass: "eD8CF0 mr10" }, [
                                  _vm._v(
                                    _vm._s(
                                      activity.type == 1
                                        ? "电话"
                                        : activity.type == 2
                                        ? "微信"
                                        : activity.type == 3
                                        ? "QQ"
                                        : activity.type == 4
                                        ? "面聊"
                                        : ""
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(activity.realname))]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "下次回访日期：" +
                                      _vm._s(activity.next_time)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(activity.content))]),
                              ]
                            )
                          }),
                          1
                        )
                      : _c(
                          "h3",
                          {
                            staticStyle: {
                              "text-align": "center",
                              color: "#ccc",
                            },
                          },
                          [_vm._v("暂无跟进记录")]
                        ),
                  ],
                  1
                ),
              ]),
            ])
          }),
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.dialogShow
      ? _c("div", { staticClass: "drag" }, [
          _c(
            "div",
            {
              directives: [{ name: "drag", rawName: "v-drag" }],
              staticClass: "drag_box",
            },
            [
              _c("div", { staticClass: "title" }, [
                _c("span", [_vm._v("来电提醒")]),
                _vm._v(" "),
                _c("i", {
                  staticClass: "iconfont icon-cha",
                  on: { click: _vm.DialogClick },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "Main" }, [
                _vm._v("\n                您有\n                "),
                _c("span", [_vm._v(_vm._s(_vm.calls))]),
                _vm._v(" "),
                _c("span", [_vm._v("（" + _vm._s(_vm.MobileAddress) + "）")]),
                _vm._v("的来电请您到客户列表电话条处接听\n            "),
              ]),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }